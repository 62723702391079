// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  background-color: #f6f0eb;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.0509803922);
}
@media (min-width: 991px) {
  .header {
    height: 100px;
    padding-inline: 64px;
  }
}
@media (min-width: 991px) and (max-height: 750px) {
  .header {
    height: 100px;
  }
}
.header .logo {
  height: 60px;
}
@media (max-width: 992px) {
  .header .logo {
    height: 27px;
  }
}
.header .title {
  font-size: 16px;
  color: #000;
}
.header app-burger-btn {
  display: block;
  margin-left: auto;
  margin-right: -20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACC,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,oBAAA;EAEA,yBAAA;EACA,wDAAA;AAFD;ACRE;EDCF;IAYE,aAAA;IACA,oBAAA;EADA;AACF;AACE;EAdF;IAeG,aAAA;EAED;AACF;AACC;EACC,YAAA;AACF;AChBE;EDcD;IAGE,YAAA;EAGD;AACF;AAAC;EACC,eAAA;EACA,WAAA;AAEF;AAAC;EACC,cAAA;EACA,iBAAA;EACA,mBAAA;AAEF","sourcesContent":["@import '../../../theme/mixins';\n\n.header {\n\twidth: 100%;\n\theight: 55px;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding-inline: 24px;\n\n\tbackground-color: #f6f0eb;\n\tbox-shadow: 0px 4px 60px 0px #0000000d;\n\n\t@include desktop {\n\t\theight: 100px;\n\t\tpadding-inline: 64px;\n\t\t@media (max-height: 750px) {\n\t\t\theight: 100px;\n\t\t}\n\t}\n\n\t.logo {\n\t\theight: 60px;\n\t\t@include mobile {\n\t\t\theight: 27px;\n\t\t}\n\t}\n\n\t.title {\n\t\tfont-size: 16px;\n\t\tcolor: #000;\n\t}\n\tapp-burger-btn {\n\t\tdisplay: block;\n\t\tmargin-left: auto;\n\t\tmargin-right: -20px;\n\t}\n}\n","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
