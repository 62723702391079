import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { getAvailability, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { AllergensInfoModalComponent } from '../allergens-info-modal/allergens-info-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-article-option-group-single',
	templateUrl: './article-option-group-single.component.html',
	styleUrls: ['article-option-group-single.component.scss'],
})
export class ArticleOptionGroupSingleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Output() openInfo = new EventEmitter<Article>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	selectedItem: Article;
	numberToCurrency = numberToCurrency;
	di = DisplayIdentifier;
	showFirstArticles = true;
	constructor(private modalCtrl: ModalController) {}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	selectedOptionIndex() {
		const indexSelection = this.selected.findIndex(selectedOption => selectedOption.group === this.optionGroup._id);
		if (indexSelection < 0) {
			return indexSelection;
		}
		return this.optionGroup.articles.findIndex(article => article._id === this.selected[indexSelection].article._id);
	}

	onSelectionChange(article: Article) {
		this.selectedItem = article;
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
		console.log(this.selectedItem, article);
	}

	isDisabled(option: Article): boolean {
		return option.requirements && option.requirements.min > 0 && this.isOptionSelected(option);
	}

	price(option: Article): number {
		let price = getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		return price;
	}

	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0
		);
	}

	isOptionSelected(option: Article): boolean {
		return this.selected.find(sel => sel.article._id === option._id && sel.group === this.optionGroup._id) !== undefined;
	}

	async openInfoClick(option: Article) {
		await AllergensInfoModalComponent.show(this.modalCtrl, option);
	}
	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: any) {
		if (!article?.nutritions) {
			return null;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
}
