// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p, li, ol, h3, h4 {
  color: black !important;
}

div {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/privacy/privacy.page.scss"],"names":[],"mappings":"AACA;EACE,uBAAA;AAAF;;AAGA;EACE,YAAA;AAAF","sourcesContent":["\np,li,ol,h3,h4 {\n  color: black !important;\n}\n\ndiv {\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
