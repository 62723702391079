import { Component, EventEmitter, Input, Output } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { environment } from '../../../environments/environment';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import { getPrice, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-item-selectable-article-custom',
	templateUrl: './item-selectable-article-custom.component.html',
	styleUrls: ['item-selectable-article-custom.component.scss'],
})
export class ItemSelectableArticleCustomComponent {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	@Input() group: OptionGroup;
	@Output() add = new EventEmitter<Article>();
	@Output() remove = new EventEmitter();
	@Input() currency: string;
	numberToCurrency = numberToCurrency;

	constructor() {}

	get price(): number {
		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	get isEmptyArticle(): boolean {
		return TagUtils.hasEmptyTag(this.article);
	}
	addItem() {
		if (this.isEmptyArticle) {
			this.add.emit();
			return;
		}
		if (this.group && !this.group.hasMultiple) {
			this.add.emit();
			return;
		}
		if (this.group && this.group.hasMultiple && this.selected == 0) {
			this.add.emit();
			return;
		}
		// this.isEmptyArticle || ? this.add.emit() : null
	}
}
