import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AllowGpsModalResult } from '../allow-gps-modal/allow-gps-modal.component';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';
import { SwiperOptions } from 'swiper/types';
import { AppComponent } from 'src/app/app.component';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-scrolling-categories',
	standalone: true,
	imports: [CommonModule, IonicModule, TranslateModule],
	templateUrl: './scrolling-categories.component.html',
	styleUrls: ['./scrolling-categories.component.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollingCategoriesComponent implements AfterViewInit {
	categories: ArticleCategory[] = [];
	defaultCategoriesLength = 0;
	selectedCategory = null;
	private _selectedCategoryIndex = 0;
	@Input() set selectedCategoryIndex(value: number) {
		if ((value || value === 0) && this._selectedCategoryIndex !== value) {
			this._selectedCategoryIndex = value;
			this.goToIndex(value);
		}
	}
	@Input() set articleCategories(value: ArticleCategory[]) {
		console.log('INCOME', value);
		if (value && value.length != this.defaultCategoriesLength) {
			this.defaultCategoriesLength = value.length;
			this.categories = value;
			this.selectedCategory = this.categories[0];
		}
	}
	@Output() outputSelectedCategory = new EventEmitter<number>();
	@ViewChild('categoryList', { static: false }) categoryList: ElementRef;
	amountOfSlides = 0;

	constructor() {
		console.log('innerWidht');
		this.amountOfSlides = window.innerWidth / (AppComponent.largeScreen ? 122 : 80);
	}
	ngAfterViewInit(): void {}

	goToIndex(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo && !this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}

		this.categoryList.nativeElement.scrollTo({
			left:
				this.categoryList.nativeElement.children[index].offsetLeft +
				60 -
				this.categoryList.nativeElement.getBoundingClientRect().width / 2,
			behavior: 'smooth',
		});
		this.selectedCategory = this.categories[index];
	}

	async selectIndex(index: number) {
		this.outputSelectedCategory.emit(index % this.defaultCategoriesLength);
	}
}
