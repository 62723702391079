// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 90%;
  margin: 15px auto;
}

h3 {
  font-family: AppFontBold, sans-serif;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0em;
}

ion-item {
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  font-size: 16px !important;
  color: black;
}
ion-item ion-label {
  margin-left: 10px;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.button-block ion-button {
  color: var(--ion-color-secondary);
  border: 2px solid var(--ion-color-secondary);
  --border-radius: 28px;
  border-radius: 28px;
  width: 48%;
  height: 57px;
  font-size: 15px;
  text-transform: none;
  font-family: AppFontBold, sans-serif;
  border: 2px solid var(--ion-color-secondary);
}
.button-block .button:last-child {
  border: none;
  --border-radius: 28px;
  color: white;
}

ion-icon {
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/app/components/add-custom-tip/add-custom-tip.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;AACJ;;AACA;EACI,oCAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;;AACA;EACI,yBAAA;EACA,kBAAA;EACA,0BAAA;EACA,YAAA;AAEJ;AADI;EACI,iBAAA;AAGR;;AACA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAEJ;AADI;EAGI,iCAAA;EACA,4CAAA;EACA,qBAAA;EACA,mBAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;EACA,oBAAA;EACA,oCAAA;EACA,4CAAA;AACR;AACI;EAGI,YAAA;EACA,qBAAA;EACA,YAAA;AADR;;AAIA;EACI,WAAA;AADJ","sourcesContent":[".wrapper {\n    width: 90%;\n    margin: 15px auto;\n}\nh3 {\n    font-family: AppFontBold ,sans-serif;\n    font-size: 18px;\n    line-height: 25px;\n    letter-spacing: 0em;\n    \n}\nion-item {\n    border: 1px solid #e5e5e5;\n    border-radius: 7px;\n    font-size: 16px !important;\n    color: black;\n    ion-label {\n        margin-left: 10px;\n    }\n}\n\n.button-block {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 5px;\n    ion-button {\n\n\n        color: var(--ion-color-secondary);\n        border: 2px solid var(--ion-color-secondary);\n        --border-radius: 28px;\n        border-radius: 28px;\n        width: 48%;\n        height: 57px;\n        font-size: 15px;\n        text-transform: none;\n        font-family: AppFontBold, sans-serif;\n        border: 2px solid var(--ion-color-secondary);\n    }\n    .button:last-child {\n        // --background: var(--ion-color-secondary);\n        // background: var(--ion-color-secondary);\n        border: none;\n        --border-radius: 28px;\n        color: white;\n    }\n}\nion-icon {\n    z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
