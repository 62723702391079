import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { createTranslateLoader } from '../app.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-filters-additional-options',
	standalone: true,
	imports: [CommonModule, IonicModule, TranslateModule],
	template: `
		<div class="filters_block">
			<div class="filters_block_title">
				<h3>{{ 'filters.filter_title' | translate }}</h3>
				<ion-icon (click)="hideFilter()" src="../../../assets/icon/cross.svg"></ion-icon>
			</div>
			<div class="wrapper_blocker">
				<div class="filters_block_dotted"></div>
			</div>

			<div>
				<h5>{{ 'filters.info' | translate }}</h5>
				<ul>
					<li *ngFor="let filterItem of filterList">
						<div class="wrapper" (click)="selectFilter(filterItem.value)">
							<div class="circle">
								<div class="cirle_inner" [class.selected]="addedFilters.includes(filterItem.value)"></div>
							</div>
							<div class="info_block">
								<p>{{ filterItem.text | translate }}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="filters_block_button">
				<ion-button (click)="selectFilters()">{{ 'filters.filter_btn' | translate }}</ion-button>
			</div>
		</div>
	`,
	styleUrls: ['./filters-additional-options.component.scss'],
})
export class FiltersAdditionalOptionsComponent {
	addedFilters: string[] = [];
	@Input() set filters(value: string[]) {
		this.addedFilters = value;
	}
	@Output() selectedFiltersValues = new EventEmitter<string[]>();
	@Output() hideFilterPanel = new EventEmitter<void>();
	public filterList = [
		{
			value: 'glutenFree',
			text: 'filters.glutenfrie',
		},
		{
			value: 'lactoseFree',
			text: 'filters.kohlenhydratfrie',
		},
	];
	constructor() {}
	selectFilter(filter: string) {
		if (this.addedFilters.includes(filter)) {
			this.addedFilters = this.addedFilters.filter(it => it !== filter);
			return;
		}
		this.addedFilters.push(filter);
	}
	selectFilters() {
		this.selectedFiltersValues.emit(this.addedFilters);
		this.hideFilterPanel.emit();
	}
	hideFilter() {
		this.hideFilterPanel.emit();
	}
}
