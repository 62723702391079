import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Order from '../../../smoothr-web-app-core/models/Order';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from '../../../environments/environment';
import { numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';
import { NavigationService } from 'src/app/services/navigation.service';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-to-order-button',
	templateUrl: './to-order-button.component.html',
	styleUrls: ['to-order-button.component.scss'],
})
export class ToOrderButtonComponent {
	@Input() order: Order;
	@Input() venue: Venue;

	constructor(
		private router: Router,
		private navigationService: NavigationService
	) {}

	get price(): number {
		if (!this.order || !this.venue) {
			return 0;
		}
		let mappedOrder = JSON.parse(JSON.stringify(this.order)) as Order;

		const foundArticleIndex = this.order.orderedArticles.findIndex(it => it?.isHalfPizza);
		if (foundArticleIndex >= 0) {
			const foundArticle = this.venue.articleCategories
				.map(it => it?.articles?.find(ar => ar?.tags?.find(tag => tag?.identifier == 'appliesMostExpensive')))
				.filter(it => !!it);

			if (foundArticle.length > 0) {
				mappedOrder.orderedArticles = mappedOrder.orderedArticles.map(it => {
					if (it.isHalfPizza) {
						it.article = foundArticle[0];
					}
					return it;
				});
			}
		}
		return OrderUtils.articleGroupsTotalPrice(
			mappedOrder.orderedArticles,
			mappedOrder.type,
			mappedOrder.preorder.type,
			mappedOrder.terminalorder ? mappedOrder.terminalorder.type : null
		);
	}

	get formattedPrice(): string {
		return numberToCurrency(this.price, this.order.currency);
	}

	get active(): boolean {
		return this.price > 0;
	}

	async goToOrder() {
		if (this.active) {
			await this.navigationService.order();
		}
	}
}
