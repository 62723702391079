// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  color: white;
}

p {
  line-height: 22px;
}

h1 {
  margin: 0;
  padding: 0 4px;
}

ion-icon {
  width: 48px;
  height: 48px;
}

ion-row {
  padding: 8px;
  background: var(--ion-color-primary);
  text-align: center;
}
ion-row ion-col {
  padding: 12px;
}

.add-to-order-button {
  color: var(--ion-color-primary);
  background: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/short-info-modal/short-info-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,SAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,oCAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;AACJ;;AAGA;EACE,+BAAA;EACA,iBAAA;AAAF","sourcesContent":["* {\n  color: white;\n}\n\np {\n  line-height: 22px;\n}\n\nh1 {\n  margin: 0;\n  padding: 0 4px;\n}\n\nion-icon {\n  width: 48px;\n  height: 48px;\n}\n\nion-row {\n  padding: 8px;\n  background: var(--ion-color-primary);\n  text-align: center;\n\n  ion-col {\n    padding: 12px\n  }\n}\n\n.add-to-order-button {\n  color: var(--ion-color-primary);\n  background: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
