// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  margin: 3px 0;
  border: 1px solid #E6E7E3;
  border-radius: 5px;
  box-shadow: none;
  word-break: break-word;
  --color: black;
  --preorder-type-chip-border-color: #F2F2F2;
  --preorder-type-chip-background: #F2F2F2;
  --preorder-type-chip-color: var(--ion-color-primary);
}
ion-card ion-card-content {
  border-top: 1px solid #E6E7E3;
  padding: 10px;
}
ion-card ion-card-content:first-of-type {
  border: none;
}

ion-col b {
  font-family: AppFont, sans-serif;
}

.your-order-title {
  text-transform: uppercase;
  color: var(--ion-color-secondary);
  font-family: AppFontBold, sans-serif;
}

.header {
  font-size: 20px;
  font-family: AppFontExtraBold, sans-serif;
}

ion-chip {
  background: #E2DCD5;
  color: var(--ion-color-primary);
  height: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/my-order/my-order.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,0CAAA;EACA,wCAAA;EACA,oDAAA;AAAF;AACE;EACE,6BAAA;EACA,aAAA;AACJ;AACI;EACE,YAAA;AACN;;AAIE;EACE,gCAAA;AADJ;;AAKA;EACE,yBAAA;EACA,iCAAA;EACA,oCAAA;AAFF;;AAIA;EACE,eAAA;EACA,yCAAA;AADF;;AAGA;EACE,mBAAA;EACA,+BAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AAAF","sourcesContent":["\nion-card {\n  margin: 3px 0;\n  border: 1px solid #E6E7E3;\n  border-radius: 5px;\n  box-shadow: none;\n  word-break: break-word;\n  --color: black;\n  --preorder-type-chip-border-color:  #F2F2F2;;\n  --preorder-type-chip-background:  #F2F2F2;;\n  --preorder-type-chip-color: var(--ion-color-primary);\n  ion-card-content {\n    border-top: 1px solid #E6E7E3;\n    padding: 10px;\n\n    &:first-of-type {\n      border: none;\n    }\n  }\n}\nion-col {\n  b {\n    font-family: AppFont, sans-serif;\n  }\n}\n\n.your-order-title {\n  text-transform: uppercase;\n  color: var(--ion-color-secondary);\n  font-family: AppFontBold, sans-serif;\n}\n.header {\n  font-size: 20px;\n  font-family: AppFontExtraBold, sans-serif;\n}\nion-chip{\n  background:  #E2DCD5;\n  color: var(--ion-color-primary);\n  height: 20px;\n  padding-top: 0px;\n  padding-bottom: 0px;\n  margin: 0px;\n  font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
