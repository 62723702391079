// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0px;
  padding: 20px;
  z-index: 99;
  background: white;
}
.footer_block ion-button {
  width: 48%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 30px 15px;
  overflow: auto;
}

.content {
  width: 100%;
  height: auto;
  overflow: auto;
  padding-bottom: 60px;
}
.content app-article-option-group-checkbox {
  width: 100%;
}

.wrapper-header {
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/show-remove-ingrediants-dialog/show-remove-ingrediants-dialog.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EAIA,iBAAA;AAHJ;AAAI;EACI,UAAA;AAER;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;AACJ;;AACA;EACI,WAAA;EACA,YAAA;EACA,cAAA;EAIA,oBAAA;AADJ;AAFI;EACI,WAAA;AAIR;;AAAA;EACI,WAAA;EACA,kBAAA;AAGJ","sourcesContent":["\n.footer_block {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    position: fixed;\n    bottom: 0px;\n    padding: 20px;\n    z-index: 99;\n    ion-button {\n        width: 48%;\n    }\n    background: white;\n}\n.wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    height: auto;\n    padding: 30px 15px;\n    overflow: auto;\n}\n.content {\n    width: 100%;\n    height: auto;\n    overflow: auto;\n    app-article-option-group-checkbox {\n        width: 100%;\n    }\n    padding-bottom: 60px;\n}\n.wrapper-header {\n    width: 100%;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
