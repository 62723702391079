import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IonInput, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import Address from 'src/smoothr-web-app-core/models/Address';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import { environment } from 'src/environments/environment';
import { venueAcceptsOrders } from 'src/smoothr-web-app-core/utils/utils';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import { MapsUtils } from 'src/smoothr-web-app-core/utils/maps-utils';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';

@Component({
	selector: 'app-map-search-venue',
	templateUrl: './map-search-venue.component.html',
	styleUrls: ['./map-search-venue.component.scss'],
})
export class MapSearchVenueComponent extends RepositoryDirective implements OnInit {
	@ViewChild(IonInput, { static: true }) inputField: IonInput;
	@Input() selectedVenue: Venue;
	loading = true;
	venuesLoaded = false;
	searchTerm: string;
	address: Address;
	showEmpty = false;
	suggestedPlace: Address;
	deliveryVenueAvailable = false;
	predictions: AutocompletePrediction[] = [];
	addressToString = MapsUtils.addressToString;
	showPredictions = true;
	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,

		private snackbarCtrl: MatSnackBar,
		private snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		public platform: Platform
	) {
		super(repository);
	}

	async ngOnInit() {
		MapsUtils.getUserGeocode().then(result => {
			this.suggestedPlace = result;
		});

		await this.initAutocomplete();
	}

	async loadPlace(pred: any) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
			this.predictions = [];
		} catch (e) {
			await this.fillInPlace(pred);
		}

		this.loading = false;
		this.cdr.detectChanges();
	}

	async resetSearch() {
		(await this.inputField.getInputElement()).value = '';
		this.searchTerm = '';
		this.venuesLoaded = false;
		this.deliveryVenueAvailable = false;
		this.predictions = [];
	}

	async loadVenues(address: Address) {
		this.deliveryVenueAvailable = false;
		this.venuesLoaded = false;
		if (address.street === null || address.street === undefined) {
			this.address = null;
			this.loading = false;
			this.snackBar.open(this.translate.instant('home_page.street_error'), null, {
				duration: 2000,
			});
			this.cdr.detectChanges();
			return;
		}
		try {
			const foundVenue = (await this.repository.getVenuesByAddress(environment.customerGroup, address)).find(
				ven => ven._id === this.selectedVenue._id && venueAcceptsOrders(ven, PreorderType.DELIVERY)
			);
			this.deliveryVenueAvailable = foundVenue !== undefined;
			this.venuesLoaded = true;
		} catch (e) {
			console.error(e);
			this.venuesLoaded = false;
		}
		this.loading = false;
		this.cdr.detectChanges();
	}
	async foundPlace(ionInput: IonInput, address: Address, searchTerm: (searchTerm: string | null) => string) {
		if (!address) {
			return;
		}
		const addressError = ValidationUtils.validateAddress(address);
		if (addressError) {
			console.error('Maps error: ' + addressError);
			throw addressError;
		}
		ionInput.getInputElement().then(el => {
			el.value = this.addressToString(address);
			searchTerm(el.value);
		});
		return;
	}

	async fillInPlace(address: Address) {
		this.loading = true;
		try {
			await this.foundPlace(this.inputField, address, term => {
				if (term) {
					this.searchTerm = term;
				}
				return this.searchTerm;
			});
			if (!address) {
				await this.executeSearch();
				return;
			}
			this.address = address;
			await this.loadVenues(address);
			this.loading = true;
		} catch (e) {
			this.snackBar.open(this.translate.instant(e), null, {
				duration: 2000,
			});
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async executeSearch() {
		console.log('here');
		this.predictions = [];
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			console.log('result', result);

			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async initAutocomplete() {
		this.showEmpty = false;
		if (this.inputField) {
			await MapsUtils.initAutocomplete(this.inputField, predictions => {
				console.log(predictions);
				this.predictions = predictions;
				this.showEmpty = predictions.length === 0;
				this.cdr.detectChanges();
			});
		}

		this.loading = false;
	}
	public changeAddress(value: any) {
		this.searchTerm = value?.detail?.value;
	}
	hidePredictions() {
		this.showPredictions = false;
	}
}

// searchTerm = '';
// @Input() showSearch: boolean = true;
// @Input() address: Address = null;
// @Output() selectVenue = new EventEmitter<Venue | null>();
// @Output() selectVenueToDelivery = new EventEmitter<Venue | null>();
// @Output() changeSlide = new EventEmitter<void>();
// @Output() changesAddress = new EventEmitter<Address | null>();

// @ViewChild(IonInput, { static: true })
// inputField: IonInput;
// loading = false;
// showHint = false;
// loadingAddress = false;
// MapsUtils = MapsUtils;
// predictions: Address[] = [];
// private _showPredictions = false;
// constructor(
// 	private snackbarCtrl: MatSnackBar,
// 	private cdr: ChangeDetectorRef,
// 	public translate: TranslateService
// ) {}

// ngAfterViewInit() {
// 	new Promise(async () => {
// 		await this.fillInPlace(null);
// 		await this.initAutocomplete();
// 	});
// }
// hidePredictions() {
// 	setTimeout(() => {
// 		this._showPredictions = false;
// 	}, 200);
// }
// async initAutocomplete() {
// 	try {
// 		console.log(this.inputField);
// 		await MapsUtils.initAutocomplete(
// 			this.inputField,
// 			predictions => {
// 				this.predictions = predictions;
// 				this.cdr.detectChanges();
// 			},
// 			loading => (this.loadingAddress = loading)
// 		);
// 		this.loading = false;
// 	} catch (e) {
// 		this.loading = false;
// 	}
// }
// showPredictions() {
// 	this._showPredictions = true;
// }

// async executeSearch() {
// 	console.log('executeSearch()');
// 	this.loading = true;
// 	try {
// 		const result = await MapsUtils.executeSearch(this.inputField);
// 		await this.fillInPlace(result);
// 	} catch (e) {}
// 	this.loading = false;
// }

// resetSearch() {
// 	console.log('resetSearch()');
// 	this.searchTerm = '';
// 	this.predictions = [];
// 	this.selectVenueToDelivery.emit(null);
// 	this.selectVenue.emit(null);
// 	if (this.address) {
// 		this.changesAddress.emit(null);
// 	}
// 	this.showHint = false;
// 	this.cdr.detectChanges();
// }
// async fillInPlace(address: Address) {
// 	console.log('fillInPlace()');
// 	this.loading = true;
// 	try {
// 		this.searchTerm = MapsUtils.checkAddress(address);
// 		this.loading = false;
// 		if (this.address !== address) {
// 			this.changesAddress.emit(address);
// 		}
// 		this.changeSlide.emit();
// 	} catch (e) {
// 		console.error(e);
// 		this.snackbarCtrl.open(this.translate.instant(e), null, {
// 			duration: 2000,
// 		});
// 	}
// 	this.loading = false;
// }
// async loadPlaceFunc(prediction: Address) {
// 	if (this.loading) {
// 		return;
// 	}
// 	this.loading = true;
// 	await this.fillInPlace(prediction);
// 	this.loading = false;
// 	this.cdr.detectChanges();
// }
// public changeAddress(value: any) {
// 	this.searchTerm = value?.detail?.value;
// }
