// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 90%;
  margin: 15px auto;
}

h3 {
  font-family: AppFontBold, sans-serif;
  margin-top: 15px;
  margin-bottom: 5px;
}

ion-input {
  margin-top: 10px;
  --padding-start: 10px !important;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.button-block ion-button {
  color: var(--ion-color-secondary);
  width: 45%;
  font-size: 18px;
  font-family: AppFontBold, sans-serif;
}

ion-icon {
  z-index: 10;
}

span {
  margin: 10px 0px;
}

.email-msg {
  margin: 0px;
  margin-bottom: 0px;
}

ion-item {
  --border-width: 0px !important;
  border: none;
  border-bottom: 1.2px solid var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-restore-password-modal/app-restore-password-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;AACJ;;AACA;EACI,oCAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,gBAAA;EACA,gCAAA;AAGJ;;AADA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAIJ;AAHI;EAEI,iCAAA;EACA,UAAA;EACA,eAAA;EACA,oCAAA;AAIR;;AAAA;EACI,WAAA;AAGJ;;AADA;EACI,gBAAA;AAIJ;;AAFA;EACI,WAAA;EACA,kBAAA;AAKJ;;AAHA;EACI,8BAAA;EACA,YAAA;EACA,mDAAA;AAMJ","sourcesContent":[".wrapper{\n    width: 90%;\n    margin: 15px auto;\n}\nh3 {\n    font-family: AppFontBold, sans-serif;\n    margin-top: 15px;\n    margin-bottom: 5px;\n}\nion-input {\n    margin-top: 10px;\n    --padding-start: 10px !important;\n}\n.button-block{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 10px;\n    ion-button {\n\n        color: var(--ion-color-secondary);\n        width: 45%;\n        font-size: 18px;\n        font-family: AppFontBold, sans-serif;\n    }\n\n}\nion-icon{\n    z-index: 10;\n}\nspan {\n    margin: 10px 0px;\n}\n.email-msg{\n    margin: 0px;\n    margin-bottom: 0px;\n}\nion-item {\n    --border-width: 0px !important;\n    border: none;\n    border-bottom: 1.2px solid var(--ion-color-primary);\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
