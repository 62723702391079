// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item.input-error {
  margin-bottom: 8px !important;
}

ion-radio-group > ion-row {
  gap: 5px;
  margin-bottom: 8px;
}

ion-item {
  --border-width: 1px !important;
  border: none;
}

.no-line {
  border-bottom: 0px;
  --background: transparent ;
}

.wrapper {
  padding: 20px;
}

.privacy {
  font-size: 12px;
  color: var(--ion-color-black);
}
.privacy ion-button {
  padding: 0px;
  color: var(--ion-color-primary);
  text-transform: capitalize;
}

.privacy-btn {
  margin-left: 5px;
}

ion-note {
  font-size: 12px;
  padding-right: 10px;
}

ion-item {
  --border-radius: 5px;
  margin-bottom: 0;
}

.stacked {
  position: relative;
  z-index: 2;
  left: 20px;
  top: 7px;
  background: white;
  padding: 0px 5px;
}

.terms {
  min-width: 360px;
  margin-left: 0px;
}

ion-checkbox {
  margin-left: 0px;
}

.stacked {
  position: static;
  top: 0px;
  left: 0px;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sign-up/sign-up.page.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;AADF;;AAIA;EACE,QAAA;EACA,kBAAA;AADF;;AAGA;EACE,8BAAA;EACA,YAAA;AAAF;;AAEA;EACE,kBAAA;EACA,0BAAA;AACF;;AACA;EACE,aAAA;AAEF;;AAAA;EACE,eAAA;EACA,6BAAA;AAGF;AAFE;EACE,YAAA;EACA,+BAAA;EACA,0BAAA;AAIJ;;AADA;EACE,gBAAA;AAIF;;AADA;EACE,eAAA;EACA,mBAAA;AAIF;;AADA;EACE,oBAAA;EACA,gBAAA;AAIF;;AADA;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,QAAA;EACA,iBAAA;EACA,gBAAA;AAIF;;AADA;EACE,gBAAA;EACA,gBAAA;AAIF;;AADA;EACE,gBAAA;AAIF;;AAAA;EACC,gBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AAGD","sourcesContent":["@import \"../../../theme/mixins\";\n\nion-item.input-error {\n  margin-bottom: 8px !important;\n}\n\nion-radio-group > ion-row {\n  gap: 5px;\n  margin-bottom: 8px;\n}\nion-item {\n  --border-width: 1px !important;\n  border: none;\n}\n.no-line {\n  border-bottom: 0px;\n  --background: transparent\n}\n.wrapper{\n  padding: 20px;\n}\n.privacy{\n  font-size: 12px;\n  color: var(--ion-color-black);\n  ion-button {\n    padding: 0px;\n    color: var(--ion-color-primary);\n    text-transform: capitalize;\n  }\n}\n.privacy-btn{\n  margin-left: 5px;\n}\n\nion-note {\n  font-size: 12px;\n  padding-right: 10px;\n}\n\nion-item {\n  --border-radius: 5px;\n  margin-bottom: 0;\n}\n\n.stacked {\n  position: relative;\n  z-index: 2;\n  left: 20px;\n  top: 7px;\n  background: white;\n  padding: 0px 5px;\n}\n\n.terms {\n  min-width: 360px;\n  margin-left: 0px;\n}\n\nion-checkbox {\n  margin-left: 0px;\n}\n\n\n.stacked {\n\tposition: static;\n\ttop: 0px;\n\tleft: 0px;\n\tz-index: 2;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
