import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const losteriaCustomerGroup = 'losteria';
export const customerGroup = losteriaCustomerGroup;

const supportEmail = {
	losteria: 'losteria@smoothr.de',
};
const firebaseConfig = {
	losteria: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:8dfd26fcf807b976cbfff0',
		measurementId: 'G-NTQ5JGCQ5W',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDAdrZ5vG1_A-5rChcnhh6UQjfhX1OI9QE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	gaId: '',
};
