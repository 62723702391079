import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input()
	isBack: boolean = false;
	@Input()
	title: string = '';
	@Input()
	src: string;
	@Output() back = new EventEmitter<void>();

	constructor() {}

	ngOnInit() {}
	goBack() {
		this.back.emit();
	}
}
