import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { getAvailability } from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-custom',
	templateUrl: './article-option-group-custom.component.html',
	styleUrls: ['article-option-group-custom.component.scss'],
})
export class ArticleOptionGroupCustomComponent implements OnInit {
	@Input() optionGroup: OptionGroup;
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	@Input() customView: boolean = false;
	di = DisplayIdentifier;

	constructor() {}

	private _selected: ArticleOption[] = [];

	get selected(): ArticleOption[] {
		return this._selected;
	}

	@Input()
	set selected(value: ArticleOption[]) {
		this._selected = value;
	}

	ngOnInit() {}

	onAdd(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	onRemove(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = -1;
		this.selection.emit(articleOption);
	}

	count(article: Article) {
		return this.selected.filter(ao => ao.article._id === article._id).reduce((prev, curr) => prev + curr.quantity, 0);
	}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}
	findArticleBrot(optionGroup: OptionGroup) {
		return optionGroup.articles.find(it => it.visible);
	}
}
