export enum DisplayMode {
	DEFAULT = 'default',
	RADIO = 'radio',
	CHECKBOX = 'checkbox',
	LAUNCHER = 'launcher',
	SMART_PICKER = 'smartPicker',
	DETAIL_OVERLAY = 'detailOverlay',
	GRID = 'grid',
	CIRCLE = 'circle',
	TOP_SELLER = 'topSeller',
	DETAIL_DIALOG = 'detailDialog',
	MATRIX = 'matrix',
	BANNER = 'banner',
	DRINK_FLAVOR = 'drinkFlavor',
	FLAVOR = 'flavor',
	TOGGLE_BUTTON = 'toggleButton',
	CUSTOM = 'custom',
}
