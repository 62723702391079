// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-to-order-button {
  margin: 12px;
}

p {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-primary);
  border-radius: 50px;
  padding-inline: 15px;
  font-size: 16px;
  line-height: 20px;
  margin: 20px;
  text-align: center;
  color: white;
  font-family: AppFontBold, sans-serif;
}

.vertical-line {
  height: 55%;
  width: 0.6px;
  margin-inline: 10px;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/to-order-button/to-order-button.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,mBAAA;EACA,oBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,oCAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;AAEF","sourcesContent":[".add-to-order-button {\n  margin: 12px;\n}\np {\n  height: 45px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: var(--ion-color-primary);\n  border-radius: 50px;\n  padding-inline: 15px;\n  font-size: 16px;\n  line-height: 20px;\n  margin:20px;\n  text-align: center;\n  color: white;\n  font-family: AppFontBold, sans-serif;\n}\n\n.vertical-line {\n  height: 55%;\n  width: 0.6px;\n  margin-inline: 10px;\n  background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
