import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-select-language-popover',
	templateUrl: './select-language-popover.component.html',
	styleUrls: ['./select-language-popover.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLanguagePopoverComponent {
	public selectedLanguage: string;

	public listCountries = [
		{
			name: 'German',
			icon: '../../../assets/de.svg',
			value: 'de',
		},
		{
			name: 'English',
			icon: '../../../assets/en.svg',
			value: 'en',
		},
	];
	constructor(
		private popover: PopoverController,
		private translate: TranslateService
	) {
		this.selectedLanguage = this.translate.currentLang;
	}
	dismissWithData(selectedLanguage: string) {
		this.translate.setDefaultLang(selectedLanguage);
		this.translate.use(selectedLanguage);
		this.dismiss(selectedLanguage);
	}
	dismiss(data?: string) {
		this.popover.dismiss(data);
	}
}
