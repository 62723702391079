import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-toolbar-venue-info',
	templateUrl: './app-toolbar-venue-info.component.html',
	styleUrls: ['./app-toolbar-venue-info.component.scss'],
})
export class AppToolbarVenueInfoComponent implements OnInit {
	@Input() venue: Venue;
	@Input() order: Order;
	@Output() backClick = new EventEmitter<void>();
	constructor() {}

	ngOnInit() {}
}
