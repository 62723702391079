// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-page-toolbar {
  background: var(--order-toolbar-color);
  align-items: center;
  min-height: 56px;
  flex-wrap: nowrap;
  padding-inline: 25px;
}

.title-block {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: AppFontBold;
  font-size: 16px;
  letter-spacing: 0em;
  text-align: left;
}

ion-icon {
  height: 16px;
  width: 16px;
}

app-preorder-type-chip {
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

app-burger-btn {
  position: relative;
  right: 10px;
}
@media (min-width: 991px) {
  app-burger-btn {
    display: none;
  }
}

::ng-deep .button-solid {
  --padding-start: 0px;
  --padding-end: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/order-page-toolbar/order-page-toolbar.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACC,sCAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACC,oBAAA;AADF;;AAKA;EACE,aAAA;EACD,8BAAA;EACC,uBAAA;EACD,mBAAA;AAFD;;AAKA;EACE,wBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AAFF;;AAKA;EACC,YAAA;EACA,WAAA;AAFD;;AAKA;EACC,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFD;;AAKA;EACC,kBAAA;EACA,WAAA;AAFD;ACpCE;EDoCF;IAIE,aAAA;EAAA;AACF;;AAGA;EACC,oBAAA;EACA,kBAAA;AAAD","sourcesContent":["@import '../../../theme/mixins';\n\n.order-page-toolbar {\n\tbackground: var(--order-toolbar-color);\n\talign-items: center;\n\tmin-height: 56px;\n\tflex-wrap: nowrap;\n  padding-inline: 25px;\n\t// padding-right: 37px;\n}\n\n.title-block {\n  display: flex;\n\tflex-direction: column-reverse;\n  justify-content: center;\n\talign-items: center;\n}\n\n.title {\n  font-family: AppFontBold;\n  font-size: 16px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\nion-icon {\n\theight: 16px;\n\twidth: 16px;\n}\n\napp-preorder-type-chip {\n\tmargin-left: 3px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\napp-burger-btn {\n\tposition: relative;\n\tright: 10px;\n\t@include desktop {\n\t\tdisplay: none;\n\t}\n}\n\n::ng-deep .button-solid {\n\t--padding-start: 0px;\n\t--padding-end: 0px;\n}\n","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
