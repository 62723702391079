import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const losteriaCustomerGroup = 'losteria';
export const customerGroup = losteriaCustomerGroup;

const supportEmail = {
	losteria: 'losteria@smoothr.de',
};
const firebaseConfig = {
	losteria: {
		apiKey: 'AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:bef3e3c0318df730e0fe06',
		measurementId: 'G-SRELGR4RWQ',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDx99_I34aUK3cLhF7zTI6Uxcq6FP9qKvA',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: '',
};

import 'zone.js';
