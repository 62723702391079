// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-row {
  background: var(--ion-color-primary);
  color: white;
}
.toolbar-row ion-button {
  --background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/my-orders/my-orders.page.scss"],"names":[],"mappings":"AAAA;EAIE,oCAAA;EACA,YAAA;AAFF;AAFE;EACE,yBAAA;AAIJ","sourcesContent":[".toolbar-row {\n  ion-button {\n    --background: transparent;\n  }\n  background: var(--ion-color-primary);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
