// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  background: white;
  border: 1px solid #E6E7E3;
  border-radius: 50px;
  padding: 10px;
  margin: 10px 0 10px;
}
.card ion-row {
  align-items: center;
}

.icon-col {
  padding: 0;
}
.icon-col ion-row {
  flex-direction: row-reverse;
}
.icon-col ion-row ion-img {
  height: 24px;
  width: 39px;
  margin: 0 4px;
}
.icon-col ion-row :last-child, .icon-col ion-row :first-child {
  margin: 0;
}

.title {
  font-size: 12px;
  margin: 0 4px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/app/components/payment-methods-display/payment-methods-display.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EAEA,mBAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,UAAA;AADF;AAGE;EACE,2BAAA;AADJ;AAGI;EACE,YAAA;EACA,WAAA;EACA,aAAA;AADN;AAII;EACE,SAAA;AAFN;;AAOA;EACE,eAAA;EACA,aAAA;EACA,gBAAA;AAJF","sourcesContent":[".card {\n  background: white;\n  border: 1px solid #E6E7E3;\n  border-radius: 50px;\n  padding: 10px;\n\n  margin:10px 0 10px;\n\n  ion-row {\n    align-items: center;\n  }\n}\n\n.icon-col {\n  padding: 0;\n\n  ion-row {\n    flex-direction: row-reverse;\n\n    ion-img {\n      height: 24px;\n      width: 39px;\n      margin: 0 4px;\n    }\n\n    :last-child, :first-child {\n      margin: 0;\n    }\n  }\n}\n\n.title {\n  font-size: 12px;\n  margin: 0 4px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
