import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import { getAvailability, getPrice, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-multiple',
	templateUrl: './article-option-group-multiple.component.html',
	styleUrls: ['article-option-group-multiple.component.scss'],
})
export class ArticleOptionGroupMultipleComponent {
	optionGroup: OptionGroup = null;
	@Input() set optionGroupValue(value: OptionGroup) {
		if (value) {
			if (value.articles.length > 0) {
				value.articles = value.articles.filter(it => !this.isHidden(it) && !it.hidden);
				console.log(value.articles);
				this.optionGroup = value;
			}
		}
	}
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	ot = OrderType;
	di = DisplayIdentifier;
	showFirstArticles = true;

	constructor() {}

	increaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = 1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	decreaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = -1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	count(article: Article): number {
		return this.selected
			.filter(articleOption => articleOption.article._id === article._id)
			.reduce((prev, curr) => prev + curr.quantity, 0);
	}

	totalCount(): number {
		return this.selected.reduce((prev, curr) => prev + curr.quantity, 0);
	}

	hasEmptyTag(optionArticle: Article): boolean {
		return TagUtils.hasEmptyTag(optionArticle);
	}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}
}
