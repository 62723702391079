import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper/types';
import { register } from 'swiper/element/bundle';
import { BehaviorSubject, Subject, interval, takeUntil } from 'rxjs';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import Article from 'src/smoothr-web-app-core/models/Article';
import { defaultsToArticleOption, getPrice, numberD, numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import Order from 'src/smoothr-web-app-core/models/Order';
import { DisplayIdentifier } from 'src/smoothr-web-app-core/enums/DisplayIdentifier';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';

register();
@Component({
	selector: 'app-select-half-pizza',
	standalone: true,
	imports: [CommonModule, IonicModule, TranslateModule, SharedModuleModule],
	templateUrl: './select-half-pizza.component.html',
	styleUrls: ['./select-half-pizza.component.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelectHalfPizzaComponent implements AfterViewInit, OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@ViewChild('swiperLeft', { static: false }) sliderLeft: ElementRef | undefined;
	@ViewChild('swiperRight', { static: false }) sliderRight: ElementRef | undefined;
	@ViewChild('plate', { static: false }) plate: ElementRef | undefined;

	@Input() order: Order;
	@Input() set articleGroups(value: OptionGroup[]) {
		value = value
			.filter(it => it.tags.find(tag => tag.identifier === 'half_pizza'))
			.map(it => {
				it.articles = it.articles.filter(it => it.visible && it.isActive);
				// it.articles = it.articles.length % 2 === 0 ? it.articles : it.articles.concat(it.articles[0]);
				return it;
			});
		console.log('value', value);
		this.pagesForView = value;
	}
	@Output() selectedOptionGroups = new EventEmitter<{
		left: { article: Article; optionGroup: OptionGroup };
		right: { article: Article; optionGroup: OptionGroup };
	}>();

	@Input() articleGroup: ArticleGroup;
	@Output() dismiss = new EventEmitter<void>();

	public price = 0;
	public priceText: string = '';
	pagesForView: OptionGroup[] = [];
	public activeIndex$ = new BehaviorSubject<number>(0);
	public activeIndex = 0;
	numberToCurrency = numberToCurrency;
	public config: SwiperOptions = {
		loop: true,
		speed: 400,
		navigation: true,
		grabCursor: true,
		autoplay: true,
		initialSlide: 1,
		effect: 'coverflow',
		direction: 'vertical',
		coverflowEffect: {
			depth: 0,
			rotate: 0,
			scale: 0.9,
			stretch: -2,
			modifier: 1.1,
		},
	};
	ngOnInit(): void {
		// console.log(this.page);
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	async ngAfterViewInit() {
		await this.sliderLeft.nativeElement?.swiper.update();
		await this.sliderRight.nativeElement?.swiper.update();
		await console.log(this.sliderLeft?.nativeElement?.swiper);
		interval(2000)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				console.log('timer');
				this.privateCalcPrice();
			});
		// setTimeout(() => {
		// 	this.clickRandom();
		// }, 1000);

		// await this.sliderLeft?.nativeElement.swiper.isBeginning;
		// await this.sliderRight?.nativeElement.swiper.isBeginning;

		// await this.sliderRight.nativeElement?.swiper.update();

		await sleep(200);
		const plate = this.plate.nativeElement;
		const card = document.querySelector('.slide__card');
		const cardRect = card.getBoundingClientRect();

		this.renderer.setStyle(plate, 'height', cardRect.height + 33 + 'px');
		this.renderer.setStyle(plate, 'width', cardRect.width * 2 + 33 + 'px');
	}
	constructor(private renderer: Renderer2) {}
	slideChange(value) {
		console.log('here', value);
	}
	priceCalc(optionGroup: OptionGroup, option: Article): number {
		let price = getPrice(option, OrderType.PREORDER, this.order.preorder.type);
		if (this.fullPrice(optionGroup)) {
			price += getPrice(this.articleGroup.article, OrderType.PREORDER, this.order.preorder.type);
		}

		return price;
	}

	fullPrice(optionGroup: OptionGroup): boolean {
		return optionGroup.displayIdentifiers && optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0;
	}
	async clickRandom() {
		const firstRandom = this.getRandom(0, this.pagesForView[0].articles.length).toFixed(0);
		const secondRandom = this.getRandom(0, this.pagesForView[0].articles.length).toFixed(0);
		await this.sliderLeft.nativeElement?.swiper.slideTo(firstRandom, 1500);
		await this.sliderRight.nativeElement?.swiper.slideTo(secondRandom, 1500);
	}
	getRandom(min: number, max: number) {
		return Math.random() * (max - min) + min;
	}
	async saveChanges() {
		// await this.sliderLeft.nativeElement?.swiper.update;
		await this.sliderLeft.nativeElement?.swiper.update;
		await this.sliderRight.nativeElement?.swiper.update;

		const leftElement = await this.sliderLeft.nativeElement;
		const rightElement = await this.sliderRight.nativeElement;
		let activeIndexLeft = this.getElementIndex(leftElement);
		let activeIndexRight = this.getElementIndex(rightElement);
		console.log(this.pagesForView, activeIndexLeft, activeIndexRight);
		return this.selectedOptionGroups.emit({
			left: { article: this.pagesForView[0].articles[activeIndexLeft], optionGroup: this.pagesForView[0] },
			right: { article: this.pagesForView[1].articles[activeIndexRight], optionGroup: this.pagesForView[1] },
		});
	}
	async privateCalcPrice() {
		try {
			await this.sliderLeft.nativeElement?.swiper.update;
			await this.sliderRight.nativeElement?.swiper.update;

			const leftElement = await this.sliderLeft.nativeElement;
			const rightElement = await this.sliderRight.nativeElement;
			let activeIndexLeft = this.getElementIndex(leftElement);
			let activeIndexRight = this.getElementIndex(rightElement);
			const articleLeft = this.pagesForView[0].articles[activeIndexLeft];
			const articleRight = this.pagesForView[1].articles[activeIndexRight];
			if (articleLeft || articleRight) {
				const leftPrice = getPrice(articleLeft, OrderType.PREORDER, this.order.preorder.type);
				const rightPrice = getPrice(articleRight, OrderType.PREORDER, this.order.preorder.type);
				this.price = leftPrice > rightPrice ? leftPrice : rightPrice;
			}
		} catch (e) {
			this.price = 0;
		}
	}
	getElementIndex(element: any) {
		const arrayOfSwiperElements = element.querySelectorAll('.slide');
		let activeIndex = -1;
		arrayOfSwiperElements.forEach((slide, index) => {
			if (slide.classList.contains('swiper-slide-active')) {
				const slideId = slide.getAttribute('id');
				activeIndex = slideId;
				return;
			}
		});
		return activeIndex;
	}
	backClick() {
		this.dismiss.emit();
	}
	totalPrice() {}
	onSlideChange(event: any) {
		console.log(event);
	}
}
