// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  --background: transparent;
  margin-right: -17px;
}
ion-button ion-icon {
  height: 12px;
  width: 24px;
}
@media (min-width: 991px) {
  ion-button ion-icon {
    height: 21px;
    width: 43px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/burger-btn/burger-btn.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACC,yBAAA;EACA,mBAAA;AADD;AAGC;EACC,YAAA;EACA,WAAA;AADF;ACNE;EDKD;IAIE,YAAA;IACA,WAAA;EACD;AACF","sourcesContent":["@import '../../../theme/mixins';\n\nion-button {\n\t--background: transparent;\n\tmargin-right: -17px;\n\n\tion-icon {\n\t\theight: 12px;\n\t\twidth: 24px;\n\t\t@include desktop {\n\t\t\theight: 21px;\n\t\t\twidth: 43px;\n\t\t}\n\t}\n}\n","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
